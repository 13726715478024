import React from 'react';
import { replaceDashWithEmpty } from 'utilities';
import { active, btn, container } from './Chips.module.css';

const ButtonChip = ({ chip, activeTag, onClick }) => (
	<button type='button' className={`${btn} ${activeTag.uuid === chip.uuid ? active : ''}`} onClick={onClick}>
		{replaceDashWithEmpty(chip.slug)}
	</button>
);

const chipAll = { slug: 'all', uuid: 'all' };

export const Chips = ({ chips, activeTag, setActiveTag }) => (
	<div className={container}>
		<ButtonChip chip={chipAll} onClick={() => setActiveTag(chipAll)} activeTag={activeTag} />
		{chips.map((chip) => (
			<ButtonChip
				key={chip.uuid}
				chip={chip}
				onClick={() => setActiveTag({ slug: chip.slug, uuid: chip.uuid })}
				activeTag={activeTag}
			/>
		))}
	</div>
);
