import React, { useContext, useEffect, useState } from 'react';
import { Layout } from 'containers/Layout/Layout';
import { Seo } from 'containers/Seo/Seo';
import { DynamicComponent } from 'components/DynamicComponent';
import { Cards } from 'components/Cards/Cards';
import { Chips } from 'components/Chips/Chips';
import { useMetaDataQuery, useStoryblok } from 'hooks';
import { collectionPageBreadcrumbsSchema, sortPosts } from 'utilities';
import { NavigationContext } from 'context/navigationContext';

const Categories = ({ pageContext, location }) => {
    const { siteUrl } = useMetaDataQuery();
    const mainNavigation = pageContext.mainNavigation.items;
    const { setNavItems } = useContext(NavigationContext);
    const [activeTag, setActiveTag] = useState({ slug: 'all', uuid: 'all' });
    const story = useStoryblok(pageContext.story, location);
    const postsByCategory = sortPosts(story.postsByCategories);
    const components = story.content.body?.map((blok) => <DynamicComponent blok={blok} key={blok._uid} />);
    const subcategoryIdsFromPostsByCategory = [...new Set(postsByCategory.map((el) => el.subcategory))];
    const filteredSubcategories = story.subcategories.filter((el) =>
        subcategoryIdsFromPostsByCategory.includes(el.uuid)
    );

    const category = story.slug;
    const { meta_title, meta_description } = story.content;
    const { meta_created_at, meta_published_at } = story;
    const categoryFullURL = `${siteUrl}/${category}`;

    const mappedCardsUrls = postsByCategory.map((el) => `${siteUrl}/${el.slug}`);
    const cardsUrls = { numberOfItems: postsByCategory.length, urls: mappedCardsUrls };

    const schema = {
        url: categoryFullURL,
        image: '',
        headline: '',
        title: meta_title,
        description: meta_description,
        published: meta_created_at,
        modified: meta_published_at,
        cardsUrls,
    };

    useEffect(() => {
        setNavItems(mainNavigation);
    }, []);

    return (
        <Layout type="page">
            <Seo
                title={meta_title}
                description={meta_description}
                canonical={categoryFullURL}
                url={categoryFullURL}
                published={meta_created_at}
                modified={meta_published_at}
                isPage={true}
                type="collectionpage"
                schema={schema}
                breadcrumbs={collectionPageBreadcrumbsSchema('', category, category)}
            />
            {components}
            {filteredSubcategories.length ? (
                <Chips chips={filteredSubcategories} activeTag={activeTag} setActiveTag={setActiveTag} />
            ) : null}
            <Cards cards={postsByCategory} activeTag={activeTag} />
        </Layout>
    );
};

export default Categories;
